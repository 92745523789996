import dk from "@norsktest/dkdj";

/*
If the body has class="modal-page", and there exists at least
one element with class="btn-close" and a data attribute: data-dismiss="window",
then create event handlers that close the window when the user clicks on the
.btn-close or presses the Esc key.

Usage:

    <button type="button" 
            class="btn-close m-4" 
            data-dismiss="window" 
            title="Avbryt"
            aria-label="Avbryt"></button>

*/
export function close_modal() {
    const is_modal = () => dk.$('body').hasClass('modal-page') && !!dk.$('.btn-close[data-dismiss=window]').length;

    dk.ready(function () {
        if (is_modal()) {
            document.addEventListener('keydown', e => (e.key === 'Escape') ? window.close() : 0);
            document.querySelector('.btn-close').addEventListener('click', () => window.close());
        }
    });
}
